.gelatine {
    animation: gelatine 1s infinite !important;
}

@keyframes gelatine {
    from,
    to {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(0.95, 1.05);
    }
    50% {
        transform: scale(1.05, 0.95);
    }
    75% {
        transform: scale(0.95, 1.05);
    }
}

.bounce-in-right {
    animation: bounce-in-right 1.5s ease-in-out;
}

@keyframes bounce-in-right {
    0% {
        opacity: 0;
        transform: translateX(2000px);
    }
    60% {
        opacity: 1;
        transform: translateX(-30px);
    }
    80% {
        transform: translateX(10px);
    }
    100% {
        transform: translateX(0);
    }
}
