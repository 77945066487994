html {
    overscroll-behavior: none;
    user-select: none !important;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
    -webkit-overflow-scrolling: touch;
}
body {
    overscroll-behavior-y: contain;
    -webkit-overflow-scrolling: touch;
}

body::-webkit-scrollbar { display: none !important; }

.uoh:hover {
    text-decoration: underline;
    cursor: pointer;
}
